<template>
  <div>
    <div class="a">
      <img class="one" src="../../assets/img/WAP核心工艺_02.jpg" alt="" />
    </div>
    <div class="b">
      <img class="one" src="../../assets/img/WAP核心工艺_03.jpg" alt="" />
    </div>
    <div class="c">
      <img class="one" src="../../assets/img/WAP核心工艺_04.jpg" alt="" />
    </div>
    <div class="c">
      <img class="one" src="../../assets/img/WAP核心工艺_05.jpg" alt="" />
    </div>
    <div class="d">
      <img class="one" src="../../assets/img/WAP核心工艺_06.jpg" alt="" />
    </div>
     <div class="e">
      <img class="one" src="../../assets/img/WAP核心工艺_07.jpg" alt="" />
    </div>
    <div class="f">
      <img class="one" src="../../assets/img/WAP核心工艺_08.jpg" alt="" />
    </div>
    <div class="g">
      <img class="one" src="../../assets/img/WAP核心工艺_09.jpg" alt="" />
    </div>
     <div class="h">
      <img class="one" src="../../assets/img/WAP核心工艺_10.jpg" alt="" />
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style lang="less" scoped>
.a {
  height: 149.5px;
}
.b{
    height: 308px;
}
.c{
    height: 212px;
}
.d{
    height: 225px; 
}
.e{
    height: 180px; 
}
.f{
    height: 213px;
}
.g{
    height: 551px;
}
.h{
    height: 365px;
}
</style>